<template>
  <BaseModal
    name="event-has-started"
    size="md"
    :title="`⚠️ ${eventName}`"
    @hidden="reset"
  >
  <div class="container-information">
    <div class="d-flex justify-content-center mb-3">
      <span class="circle">
        <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.5 9.30132V14.0869M14.5 18.8725H14.512M12.4542 3.15183L2.32066 20.0689C2.11173 20.4307 2.00118 20.8409 2.00001 21.2588C1.99884 21.6766 2.10709 22.0874 2.31399 22.4504C2.52089 22.8134 2.81923 23.1158 3.17932 23.3277C3.53942 23.5396 3.94872 23.6535 4.3665 23.6581H24.6335C25.0513 23.6535 25.4606 23.5396 25.8207 23.3277C26.1808 23.1158 26.4791 22.8134 26.686 22.4504C26.8929 22.0874 27.0012 21.6766 27 21.2588C26.9988 20.8409 26.8883 20.4307 26.6793 20.0689L16.5458 3.15183C16.3326 2.80021 16.0323 2.5095 15.6739 2.30775C15.3155 2.10599 14.9112 2 14.5 2C14.0888 2 13.6845 2.10599 13.3261 2.30775C12.9677 2.5095 12.6674 2.80021 12.4542 3.15183Z" stroke="#FF4820" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
    </div>
    <div class="d-flex justify-content-center mb-3">
      <p class="sub-title"> 
        {{ $t('seller.products.ticket.text_1') }}
      </p>
    </div>
    <p class="information">
        {{ $t('seller.products.ticket.text_2') }}
    </p>
    <div class="d-flex justify-content-center mt-5">
      <BaseButton variant="black" @click="reset">
        {{ $t('seller.products.new.text_687')  }}
      </BaseButton>
    </div>
  </div>  
  
  <template v-slot:footer="{ }">
      <span></span>
    </template>
  </BaseModal>
</template>

<script>

export default {
  name: "EventNotify",
  data() {
    return {
      aprovacao_component: 0,
      loading: false,
      comentario: false,
      observation: "",
    };
  },
  props:['eventName'],
  methods: {
    reset() {
      this.$bvModal.hide("event-has-started");
    },
  },
};
</script>

<style scoped>
.container-information{
  width: 100%;
  padding: 30px !important;
}
.sub-title{
  font-size: 15px;
  font-weight: 700;
  color: var(--gray-800);
}
p {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
.information {
  color: var(--gray-300);
  text-align: center;
  font-size: 0.87em;
  font-weight: 400;
}
.container-information .circle{
  background-color: #FF482010;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5em;
  height: 5em;
  border-radius: 50%;
}
</style>