<template>
  <div>

    <EventNotify :eventName="eventName"/>
    <div>
      <header class="navbar navbar-light p-3 mt-auto ml-4 mr-4">
        <div
          class="d-flex justify-content-between w-100"
          style="align-items: center;"
        >
          <img src="@/assets/logo.png" alt="greenn-logo.png" class="logo" />
          <a target="_blank" href="https://greenn.crisp.help/pt-br/"
            >Preciso de ajuda</a
          >
        </div>
      </header>
    </div>
    <div v-if="!loading" class="bg-light bg-center">
      <div class="container-90 mt-2 mb-2 md-border-bottom mw-70">
        <div>
          <div class="d-flex flex-column-reverse flex-md-row align-items mb-4">
            <div class="w-100 w-md-50 d-flex order-1 flex-column p-3">
              <h3 class="title mb-4">Como resgatar</h3>
              <p class="label mb-4">
                {{ $t('views.seller.products.text_1290') }}
              </p>
              <p class="label mb-4">
                {{ $t('views.seller.products.text_1291') }}
              </p>
              <p class="label mb-4">
                {{ $t('views.seller.products.text_1292') }}
              </p>
              <p class="label mb-4">
                <span style="color: var(--gray-300);font-weight: 600;">{{ $t('views.seller.products.text_1293') }}</span> – {{ $t('views.seller.products.text_1294') }}
              </p>
              <p class="label mb-4 mb-md-1">
                {{ $t('views.seller.products.text_1295') }}
              </p>
              <div class="h-auto mt-auto pt-5 desk">
                <div class="tickets-amount">
                  <p class="pl-3 pr-3">
                    <span class="mr-2">
                      <svg
                        class="icon"
                        style="width: 16px; height: 16px;"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 22 22"
                        id="layer"
                      >
                        <path
                          d="M2.5,8.86l9,5.2a1,1,0,0,0,1,0l9-5.2A1,1,0,0,0,22,8a1,1,0,0,0-.5-.87l-9-5.19a1,1,0,0,0-1,0l-9,5.19A1,1,0,0,0,2,8,1,1,0,0,0,2.5,8.86ZM12,4l7,4-7,4L5,8Zm8.5,7.17L12,16,3.5,11.13a1,1,0,0,0-1.37.37,1,1,0,0,0,.37,1.36l9,5.2a1,1,0,0,0,1,0l9-5.2a1,1,0,0,0,.37-1.36A1,1,0,0,0,20.5,11.13Zm0,4L12,20,3.5,15.13a1,1,0,0,0-1.37.37,1,1,0,0,0,.37,1.36l9,5.2a1,1,0,0,0,1,0l9-5.2a1,1,0,0,0,.37-1.36A1,1,0,0,0,20.5,15.13Z"
                        ></path>
                      </svg>
                    </span>
                      {{
                        getTicketQuantity() == 0
                          ? "Todos os ingressos foram resgatados"
                          : `Existem ${getTicketQuantity()} ingressos disponíveis para resgate.`
                      }}
                  </p>
                </div>
              </div>
            </div>

            <div class="form w-100 p-2 order-3 order-md-3">
              <Transition mode="out-in">
                <TicketCard
                  v-if="showTicketCard"
                  :ticket="ticketMultiple"
                  @cardClick="showForm"
                />

                <Form
                  :assigned="selectedCard"
                  :ticketToRescue="setTicketAssigned"
                  :ticketAmount="ticketMultiple.length"
                  v-if="!showTicketCard"
                  @backToCard="ticketCard"
                />
              </Transition>
            </div>
            
            <div class="mobile container mt-4 mb-3 p-1 order-2 order-md-2">
              <div
                class="grid-mobile"
                >
                <Card
                  v-for="(item, index) in setTicketAssigned"
                  :key="index"
                  :ticket="item"
                />
              </div>
            </div>
          </div>

          <div class="desk mt-4 mb-3 p-1">
             <div class="grid-desk">
              <Card
                v-for="(item, index) in setTicketAssigned"
                :key="index"
                :ticket="item"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- second section -->
    <div v-if="!loading" >
      <div class="container-100 mt-4 mb-3 bg-center">
        <div class="d-flex align-items mb-4 mw-70">
          <div class="p-2 w-50 mr-4 banner-mobile">
            <div class="banner">
              <img
                class="desk"
                :src="this.ticketData.group[0].offer.product.images[0].path"
                alt="ticket-image"
              />

              <div class="card-body mt-3">
                <!-- Event Name -->
                <h3 class="card-title banner-title mb-3">
                  {{ firstLetterCapitalized }}
                </h3>
                <div class="d-flex">
                  <div>
                    <!-- Event Date -->
                    <p class="d-flex align-items-center banner-sub-title mb-3" v-if="this.ticketData.group[0].offer.product.start_date ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon"
                        viewBox="-3 0 26 22"
                        id="calendar"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <g
                            stroke="#000"
                            stroke-width="2"
                            transform="translate(-919 -1443)"
                          >
                            <g transform="translate(920 1444)">
                              <rect width="18" height="18" y="2" rx="2"></rect>
                              <path d="M13 0v4M5 0v4M0 8h18"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                      {{ timeFormatDate(
                          this.ticketData.group[0].offer.product
                        ) }}
                      
                    </p>
                    <!-- Event hours -->
                    <p class="d-flex align-items-center banner-sub-title mb-3" v-if="this.ticketData.group[0].offer.product">
                      <svg
                      class="icon"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      {{
                        timeFormat(
                          this.ticketData.group[0].offer.product.start_time,
                          this.ticketData.group[0].offer.product.end_time
                        )
                      }}
                    </p>

                    <!-- Event address -->
                    <p class="d-flex align-items-center banner-sub-title mb-3" v-if="this.ticketData.group[0].offer.product.address">
                      <svg
                        class="icon"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 26"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                        />
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17.8 14h0a7 7 0 1 0-11.5 0h0l.1.3.3.3L12 21l5.1-6.2.6-.7.1-.2Z"
                        />
                      </svg>
                      {{
                        locale(
                          this.ticketData.group[0].offer.product.location,
                          this.ticketData.group[0].offer.product.address.city
                        )
                      }}
                    </p>
                  </div>
                  <div class="desk ml-auto" v-if="this.ticketData.group[0].offer.product.start_date">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <CalendarSheet
                        :date="
                          setDateCalendar(
                            this.ticketData.group[0].offer.product.start_date
                          )
                        "
                      />
                    </div>
                  </div>
                </div>

                <div class="share mt-3 w-100">
                  <div class="d-flex align-items-center mb-auto">
                    <p>
                      Compartilhar
                    </p>
                    <span class="ml-auto d-flex w-25">
                      <a :href="whatsLink + pathUrl" target="_blank">
                        <font-awesome-icon
                          style="width: 18px;"
                          class="icon"
                          :icon="['fab', 'whatsapp']"
                        />
                      </a>

                      <a :href="faceLink + pathUrl" target="_blank">
                        <font-awesome-icon
                          style="width: 13px;"
                          class="icon"
                          :icon="['fab', 'facebook-f']"
                        />
                      </a>

                      <a :href="twiterLink + pathUrl" target="_blank">
                        <font-awesome-icon
                          style="width: 20px;"
                          class="icon"
                          :icon="['fab', 'twitter']"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="desk ml-4 mt-2 w-50">
            <div class="d-flex flex-column mt-2">
              <h3 class="title mb-3">Detalhes do evento</h3>
              <p class="description mb-5 mb-2">
                {{ this.ticketData.group[0].offer.product.description }}
              </p>

              <h3 class="title mb-3" v-if="this.ticketData.group[0].offer.product.address">Local do evento</h3>
              <p class="description mb-5" v-if="this.ticketData.group[0].offer.product.address"> 
                <span style="font-weight: 500;">
                  {{
                    locale(
                      this.ticketData.group[0].offer.product.location,
                      this.ticketData.group[0].offer.product.address.city
                    )
                  }}
                </span>
                <br />
                {{
                  locationDescription(
                    this.ticketData.group[0].offer.product.address
                  )
                }}
              </p>

              <h3 class="title mb-2">Organizadores</h3>
              <p class="description mb-4">
                Durante o check-in no evento, essas informações serão
                verificadas pela equipe da portaria.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Seller/Ticket/Card.vue";
import CalendarSheet from "@/components/Seller/Ticket/CalendarSheet.vue";
import TicketCard from "@/components/Seller/Ticket/TicketCard.vue";
import Form from "@/components/Seller/Ticket/Form.vue";
import TicketService from "@/services/resources/TicketService";
import moment from "moment";
import EventNotify from '@/components/Seller/Ticket/EventNotify'
const service = TicketService.build();

export default {
  components: {
    Card,
    CalendarSheet,
    Form,
    EventNotify,
    TicketCard,
  },
  data() {
    return {
      ticketId: this.$route.params.sale_id,
      ticketData: undefined,
      ticketRescue: null,
      eventStarted: true,
      eventName: "Evento desconhecido!",
      showTicketCard: true,
      ticketsAssigned: [],
      selectedCard: {},
      pathUrl: window.location,
      faceLink: "https://www.facebook.com/sharer/sharer.php?href=",
      whatsLink: "https://api.whatsapp.com/send?text=",
      twiterLink: "https://twitter.com/intent/tweet?text=",
      loading: false,
    };
  },
  beforeMount() {
    this.getTicketData();
  },
  computed: {
    firstLetterCapitalized() {
      let name = this.ticketData.group[0].offer.product.name;
      if(!name){
        return this.eventName;
      }
      
      return this.eventName = name.charAt(0).toUpperCase() + name.slice(1);
    },
    ticketMultiple() {
      let newGroup = [];

      this.ticketData.group.forEach((groupElement) => {
        groupElement.ticket.forEach((ticketElement) => {
          if (ticketElement.id !== groupElement.id) {
            newGroup.push({
              ...groupElement,
              ticket: ticketElement,
              ticket_id: ticketElement.id,
            });
          }
        });
      });

      return newGroup;
    },
    setTicketAssigned() {
      this.ticketsAssigned = [];

      this.ticketMultiple.forEach((arr) => {
        if (arr.ticket && arr.ticket.name !== null) {
          this.ticketsAssigned.push(arr);
        }
      });

      return this.ticketsAssigned;
    },
  },
  methods: {
    getTicketData() {
      this.loading = true;
      service
        .read({ id: `/form/${this.ticketId}` })
        .then((res) => {
          this.ticketData = res.ticket;
        })
        .catch(() => {
          this.$bvToast.toast("Não foi possivel buscar o lote do ingresso", {
            title: "Greenn",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          setTimeout(() => {
            this.$router.push({ name: "pageNotFound" });
          }, 5000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTicketQuantity() {
      let count = 0;

      this.ticketMultiple.forEach((arr) => {
        if (arr.ticket == null || arr.ticket.name == null) count++;
      });

      this.ticketRescue = count;

      return this.ticketRescue;
    },
    setDateCalendar(date) {
      const dateCalendar = {
        month: moment(date).format("MMM"),
        day: moment(date).format("D"),
      };

      return dateCalendar;
    },
    dateFormat(date) {
      moment.locale("pt-br");
      const dataMoment = moment(date);

      return dataMoment.format("ddd, D [de] MMMM [de] YYYY");
    },
    timeFormatDate(product) {

      // Converte para moment data.
      const eventData = moment(`${product.start_date} ${product.start_time}`);

      // Verifica se a data de início é no futuro
      if (eventData.isAfter(moment())) {
        this.eventStarted = false;
      }

      if(this.eventStarted){
        this.$bvModal.show("event-has-started");
        this.eventStarted = false;
      }
      
      // Formata a data no formato desejado
      return moment(`${product.start_date} ${product.start_time}`)
        .locale('pt-br')
        .format('ddd, D [de] MMMM [de] YYYY');
    },
    timeFormat(startTime, endTime) {      
      if(!startTime) return 'Indisponivel';

      const start = moment(startTime, "HH:mm:ss");

      if (!endTime) return start.format("HH[h]mm[m]");

      const end = moment(endTime, "HH:mm:ss");

      return start
        .format("HH[h]mm[m]")
        .concat(" - ")
        .concat(end.format("HH[h]mm[m]"));
    },
    locale(location, state) {
      let address = {
        location: location ?? "Não informado",
        state: state ?? "Não informado",
      };
      return address.location.concat(" | ").concat(address.state);
    },
    locationDescription(location) {
      let $location = location;

      return $location.street
        .concat(", ")
        .concat($location.number)
        .concat(" - ")
        .concat($location.neighborhood)
        .concat(", ")
        .concat($location.city)
        .concat(" - ")
        .concat($location.state)
        .concat(", ")
        .concat($location.zip_code);
    },
    showForm(item) {
      this.showTicketCard = false;
      this.selectedCard = item;
    },
    ticketCard() {
      this.getTicketData();
      this.showTicketCard = true;
      this.selectedCard = {};
    },
    assignedTick() {
      this.$bvToast.toast("Ingresso atribuido com sucesso", {
        variant: "success",
        title: "Sucesso",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.mw-70 {
    max-width: 72%;
  }
.bg-center{
  display: flex;
  justify-content: center;
}
.v-enter-from,
.v-leave-to {
  transform: translate(50px);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
svg {
  width: 100%;
}
.container-90 {
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
}
.logo {
  max-height: 35px; /* ajuste a altura conforme necessário */
}
.tickets-amount {
  width: 440px;
  height: auto !important;
  color: #141414;
  padding: 15px;
  margin-bottom: 10px;
  border:1px solid gray;
  background-color: white;
  border-radius: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18.29px;
}
.title {
  color: #009488;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.sub-title {
  color: #009488;
  font-weight: 500;
  font-size: 14px;
}
.label {
  color: #81858e;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}
.borders {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 30px;
}
.lote {
  p {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}
.ticket-type {
  font-size: 14px;
  color: #009488;
  font-weight: 600;
  line-height: 17.07px;
  width: 131px;
  height: 40px;
  border-radius: 10px;
  background: #0094881a;
  text-align: center;

  p {
    color: #009488;
  }
}
.banner {
  img {
    width: 100%;
    max-height: 400px;
    object-fit: cover !important;
    border-radius: 20px;
  }
  .banner-title {
    color: #141414;
    font-weight: 700;
    font-size: 24px;
    line-height: 29.26px;
  }
  .banner-sub-title {
    color: #141414;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .icon {
    width: 18px;
    height: 26px;
    margin-right: 12px;
    display: flex;
    align-items: center;
  }
}
.description {
  word-break: break-all;
  color: #141414;
  font-weight: 400;
  line-height: 24px;
}
.share {
  height: 69px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #141414;
  div {
    padding: 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #141414;
  }
  .icon {
    margin-right: 28px;
  }
}
.mobile {
  display: none;
}
.desk {
  display: block;
}
a {
  font-size: 16px;
  font-weight: 400;
  color: #141414;
  text-decoration: none;
}

.grid-desk{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch; 
  gap: 16px; 
  margin-bottom: 16px;
}

.grid-mobile{
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    justify-items: center; 
    align-items: center; 
    gap: 16px;
    margin-bottom: 16px; 
  }
@media screen and (max-width: 767px) {
  .mobile {
    display: block;
  }
  .desk {
    display: none;
  }
  .banner-mobile {
    margin-top: -30px !important;
    width: 100% !important;
  }
  .banner {
    .banner-title {
      margin-left: 10px;
    }
    .icon {
      width: 22px;
      margin-left: 10px;
    }
  }
  .share {
    span {
      margin-right: 18px;
    }
    .icon {
      margin-right: 12px;
    }
  }
  
}
</style>
<!-- 
  Tabela busca: tickets and (->) sales
-->
