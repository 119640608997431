<template>
  <div class="card m-2 ">
    <!-- {{ ticket }} -->
    <div class="card-body borders">
      <img class="confete" src="@/assets/img/confet.gif"/>

      <h5 class="card-title title mb-2">#{{ ticket.ticket.id }}</h5>
      <div class="d-flex">
        <div>
          <p class="sub-title mb-2">
            <span class="teste">{{  ticket.offer.offer_groups.name ? ticket.offer.offer_groups.name : $t('seller.products.ticket.text_5') }}</span>
          </p>
          <p class="label">{{ ticket.offer.name }}</p>
        </div>
        <div class="ml-auto">
          <div
            class="ticket-type d-flex align-items-center justify-content-center"
          >
            <p class="m-0">
              {{ ticket.ticket.name !== null ? $t('seller.products.ticket.text_6') : $t('seller.products.ticket.text_7') }}
            </p>
          </div>
        </div>
      </div>
    </div>
      <div class="ticket-dashed">
        <div class="dashed"></div>
      </div>    
      <div class="card-body-bottom borders">
        <p class="card-subtitle mt-2">{{ $t('seller.products.ticket.text_3')}}</p>
        <div class="w-100 justify-content-between d-flex">
          <div>
            <p class="sub-title mb-1 title-format" v-if="ticket.ticket">
              <span class="title-format"> {{ ticket.ticket.name ? ticket.ticket.name : $t('seller.products.ticket.text_5') }} </span>
            </p>
            <p v-if="ticket.ticket.document" class="label">{{ ticket.ticket.document }}</p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket: [Array, Object],
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
};
</script>

<style scoped lang="scss">
.card-body{
  position:relative;
  .confete{
    width: 5em;
    position: absolute !important;
    top: -50px;
    right: -20px;
    transform: rotate(40deg);
    animation: confeteAnimation 3s forwards 1;
  }
}

.card {
  flex:1;
  border: none;
  min-width: 300px;
}
.borders {
  border: none !important;
  border-radius: 10px;
  padding: 20px !important;
}
.title {
  color: #009488;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.sub-title {
  font-weight: 600;
  size: 15px;
  line-height: 22.5px;
  color: #141414;
}
.label {
  color: #7a7786;
  font-weight: 400;
  font-size: 15px;
  line-height: 22.5px;
}
.ticket-type {
  font-size: 14px;
  color: #009488;
  font-weight: 600;
  line-height: 17.07px;
  width: 131px;
  height: 40px;
  border-radius: 10px;
  background: #0094881a;
  text-align: center;

  p {
    color: #009488;
  }
}
.card-subtitle-link{
  color: #009488;
  font-family: Montserrat;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.ticket-dashed {
  background-image: 
    radial-gradient(circle at center left, rgb(245 244 245 / 60%) 12px, var(--gray-10) 14px, transparent 0), 
    radial-gradient(circle at center right, rgb(245 245 245 / 60%) 12px, var(--gray-10) 14px, transparent 0);  // background-color: #0094880D;
  background-repeat: no-repeat; 
  background-position: left, right;
  background-size: 40px 100%;
  height: 25px;
  align-items: center;
  display: grid;
  position: relative;
  justify-items: center;
  .dashed{
    border-top: 0.13em dashed var(--gray-50);
    width: calc(100% - 40px);
  }
}
.card-subtitle{
    font-size: 12px;
    color: var(--gray-300);
    margin-bottom: 5px;
}

.title-format{
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

@keyframes confeteAnimation {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
</style>